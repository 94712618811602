<template>
	<div class="orderPayBox">
		<div class="cart_content" v-if="isSuccess == false">
			<Step :steps="3"></Step>
			<div class="pay_box">
				<div class="order_info">
					<div class="order_num">
						订单提交成功，请尽快付款！订单号：{{ orderNum }}
					</div>
					<div class="payTime_payPrice Between">
						<div class="payTime order_num">
							请您在24内完成支付，否则订单会被自动取消
						</div>
						<div class="payPrice flex-center">
							应付金额
							<div class="price">￥{{ Number(goodsPrice).toFixed(2) }}</div>
							元
						</div>
					</div>
				</div>
				<div class="payment_box Around">
					<div class="list flex-center pointer" @click="radioBtn(2)">
						<input
							type="radio"
							class="radio"
							value="2"
							v-model="params.payWay"
						/>
						<img class="payment_img" src="@/assets/img/pay/wx.png" alt="" />
						<div class="payment_name">微信</div>
					</div>
					<div class="list flex-center pointer" @click="radioBtn(1)">
						<input
							type="radio"
							class="radio"
							value="1"
							v-model="params.payWay"
						/>
						<img class="payment_img" src="@/assets/img/pay/zfb.png" alt="" />
						<div class="payment_name">支付宝</div>
					</div>

					<div class="list flex-center pointer">
						<input
							type="radio"
							class="radio"
							value="3"
							@click="radioBtn(3)"
							disabled
							v-model="params.payWay"
						/>
						<img class="payment_img" src="@/assets/img/pay/yl.png" alt="" />
						<div class="payment_name">银联支付</div>
					</div>
				</div>
				<div
					class="qrCode_box flex-center column"
					v-if="params.payWay == 1 || params.payWay == 2"
				>
					<!-- <img class="qrCode_img" src="@/assets/img/pay/qrCode.png" alt=""> -->
					<div id="qrcode" class="qrcode qrCode_img" ref="qrCodeDiv"></div>
					<div class="scan_box flex">
						<img class="scan_img" src="@/assets/img/pay/scan.png" alt="" />
						<div class="hint">打开微信 扫一扫继续付款</div>
					</div>
				</div>
			</div>
		</div>
		<div class="paySuccess_box" v-else>
			<div class="success_marked flex-center">
				<img src="@/assets/img/pay/success.png" alt="" />
				你已成功付款
			</div>
			<div class="payment_price flex-center">
				<div class="label">实付金额：</div>
				<div class="price">{{ Number(goodsPrice).toFixed(2) }}</div>
			</div>
			<div class="btn_box flex">
				<div class="btn viewOrder display pointer" @click="orderBtn">
					查看订单
				</div>
				<div class="btn keepBuying display pointer" @click="buyBtn">
					继续购物
				</div>
			</div>
			<div class="coupon_box">
				<div>点击下方按钮领红包</div>
				<div>最高可领100元红包</div>
			</div>
			<div class="copyBtn display pointer">复制去微信粘贴</div>
		</div>
	</div>
</template>
<script>
import Step from './components/step.vue'
import QRCode from 'qrcodejs2'

export default {
	components: {
		Step,
	},

	data() {
		return {
			params: {
				payWay: 2,
				orderCode: '',
				token: this.$store.state.userData.token,
				// returnUrl:"http://localhost:8081/#/cart/orderPay"
				returnUrl: 'https://www.golkia-mall.com/#/cart/orderPay',
			},
			goodsPrice: 0,
			orderNum: 0,
			count: '', //倒计时
			seconds: 7200, // 2小时的秒数
			inerId: '',
			time: 0,
			isSuccess: false,
			aliPayCode: '',
		}
	},
	mounted() {
		this.aliPayCode = this.$route.query.out_trade_no
		if (this.aliPayCode != '' && this.aliPayCode != undefined) {
			this.isSuccess = true
			this.goodsPrice = this.$route.query.total_amount
			this.orderNum = this.aliPayCode
			clearInterval(this.inerId)
		} else {
			this.goodsPrice = this.$route.query.price
			this.params.orderCode = this.$route.query.orderNum
			this.orderNum = this.$route.query.orderNum
			// this.Time() //调用定时器
			this.getData()
		}
	},
	beforeDestroy() {
		//离开页面清除计时器
		clearInterval(this.inerId)
	},
	methods: {
		//获取支付二维码
		getData() {
			this.api.payGoodsOrder(this.params).then((res) => {
				if (res.code == 0) {
					if (this.params.payWay == 1) {
						const ress = res.data
						let div = document.querySelector('#payDiv')
						// 如果页面已经存在payDiv，则只要修改payDiv的innerHTML就可以了，否则提交的表单永远是第一个表单。
						if (!div) {
							div = document.createElement('div')
							div.id = 'payDiv'
							document.body.appendChild(div)
						}
						div.innerHTML = ress
						document.forms[0].submit()
					} else {
						this.$nextTick(function () {
							this.createQRCode(res.data)
						})
						this.scanCode()
					}
				}
			})
		},

		createQRCode(url) {
			new QRCode(this.$refs.qrCodeDiv, {
				text: url,
				width: 189,
				height: 189,
				colorDark: '#333333', //二维码颜色
				colorLight: '#ffffff', //二维码背景色
				correctLevel: QRCode.CorrectLevel.H, //容错率，L/M/H
			})
		},
		countDown() {
			let h = parseInt((this.seconds / (60 * 60)) % 24)
			h = h < 10 ? '0' + h : h
			let m = parseInt((this.seconds / 60) % 60)
			m = m < 10 ? '0' + m : m
			let s = parseInt(this.seconds % 60)
			s = s < 10 ? '0' + s : s
			this.count = h + '时' + m + '分' + s + '秒'
		},
		//定时器没过1秒参数减1
		Time() {
			//  clearInterval(this.time);
			this.time = setInterval(() => {
				this.seconds -= 1
				if (this.seconds == 0) {
					clearInterval(this.time)
				}
				this.countDown()
			}, 1000)
		},

		radioBtn(type) {
			const codeHtml = document.getElementById('qrcode')
			clearInterval(this.inerId)
			codeHtml.innerHTML = ''
			this.params.payWay = type
			if (this.params.payWay == 1) {
				// console.log('支付宝')
				let params = {
					outTradeNo: this.$route.query.orderNum,
					token: this.$store.state.userData.token,
				}
				this.getData()
				this.api.aliPayQueryOrder(params).then((res) => {})
			} else {
				this.getData()
			}
		},
		scanCode() {
			let params = {
				outTradeNo: this.$route.query.orderNum,
				token: this.$store.state.userData.token,
			}
			// 使用定时器轮询接口判断是否支付成功
			this.inerId = setInterval(() => {
				this.api
					.wxQueryOrder(params)
					.then((res) => {
						if (res.code === 0) {
							// 支付成功了
							// REFUND—转入退款
							//  ERROR-查询失败
							// SUCCESS—支付成功
							// NOTPAY—未支付
							// CLOSED—已关闭
							// REVOKED—已撤销(刷卡支付)
							// USERPAYING--用户支付中
							// PAYERROR--支付失败(其他原因，如银行返回失败)
							if (res.data.trade_state == 'NOTPAY') {
								// console.log('未支付!')
							} else if (
								res.data.trade_state == 'SUCCESS' &&
								res.data.result_code == 'SUCCESS' &&
								res.data.return_code == 'SUCCESS'
							) {
								this.$message.success('支付成功')
								this.isSuccess = true
								// 清除计时器
								clearInterval(this.inerId)
							} else {
								clearInterval(this.inerId)
							}
						}
					})
					.catch((err) => {})
			}, 1000)
		},
		//去购物
		buyBtn() {
			this.$router.push('/mall/goodsList')
		},
		//去订单详情
		orderBtn() {
			this.$router.push({
				path: '/userInfo/orderDetails',
				query: {
					orderCode: this.orderNum,
				},
			})
		},
	},
}
</script>
<style lang="scss" scoped>
.orderPayBox {
	.cart_content {
		width: 1200px;
		margin: 0 auto;
		margin-bottom: 30px;
		.pay_box {
			width: 100%;
			height: 614px;
			background: white;
			padding: 30px;
			box-sizing: border-box;
			.order_info {
				width: 100%;
				height: 71px;
				background: #f5f5f5;
				padding: 16px 30px 16px 42px;
				box-sizing: border-box;
				.order_num {
					font-size: 14px;
					font-family: Microsoft YaHei;
					font-weight: 400;
					color: #666666;
				}
				.payTime_payPrice {
					.payTime {
					}
					.payPrice {
						font-size: 14px;
						color: #666666;
						margin-top: 5px;
						.price {
							color: #e1251b;
						}
					}
				}
			}
			.payment_box {
				margin-top: 47px;
				.list:nth-child(3) {
					img {
						width: 62px;
					}
				}
				.list {
					.radio {
						width: 18px;
						height: 18px;
					}
					.payment_img {
						width: 39px;
						height: 39px;
						margin-left: 20px;
					}
					.payment_name {
						font-size: 24px;
						color: #333333;
						margin-left: 12px;
					}
				}
			}
			.qrCode_box {
				width: 237px;
				height: 300px;
				background: #ffffff;
				margin: 0 auto;
				box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.25);
				margin-top: 50px;
				.qrCode_img {
					width: 178px;
					height: 178px;
					margin-top: 24px;
				}
				.scan_box {
					margin-top: 30px;
					.scan_img {
						width: 34px;
						height: 34px;
					}
					.hint {
						width: 84px;
						margin-left: 15px;
						font-size: 12px;
						color: #666666;
						line-height: 18px;
						div {
						}
					}
				}
			}
		}
	}
	.paySuccess_box {
		width: 1200px;
		height: 533px;
		background: white;
		margin: 0 auto;
		margin-top: 30px;
		margin-bottom: 30px;
		padding: 30px;
		box-sizing: border-box;
		.success_marked {
			width: 100%;
			height: 80px;
			background: #f5f5f5;
			padding-left: 30px;
			font-size: 24px;
			font-family: Microsoft YaHei;
			font-weight: 400;
			color: #00bd16;
			img {
				width: 40px;
				height: 40px;
				margin-right: 20px;
			}
		}
		.payment_price {
			margin-top: 30px;
			margin-left: 46px;
			.label {
				font-size: 14px;
				color: #666666;
			}
			.price {
				font-size: 18px;
				color: #e1251b;
			}
		}
		.btn_box {
			margin-top: 36px;
			margin-left: 46px;
			.btn {
				width: 91px;
				height: 32px;
				border-radius: 3px;
				font-size: 14px;
			}
			.viewOrder {
				background: #e1251b;
				border: 1px solid #e1251b;
				color: #ffffff;
			}
			.keepBuying {
				color: #ff8a00;
				border: 1px solid #ff8a00;
				margin-left: 22px;
			}
		}
		.coupon_box {
			width: 357px;
			height: 149px;
			margin-top: 34px;
			margin-left: 46px;
			background: url('../../assets/img/pay/yhq.png') no-repeat;
			background-size: 100% 100%;
			padding-top: 32px;
			padding-left: 23px;
			box-sizing: border-box;
			div {
				font-size: 23px;
				color: #ffffff;
				margin-bottom: 10px;
			}
		}
		.copyBtn {
			width: 179px;
			height: 40px;
			background: #e1251b;
			border-radius: 3px;
			margin-top: 15px;
			margin-left: 46px;
			font-size: 16px;
			color: #ffffff;
		}
	}
}
</style>